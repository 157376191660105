<template>
  <div class="recebimento-form">
    <b-alert
      v-if="error"
      show
      variant="danger"
      dismissible
      @dismissed="error = false"
      class="mb-3"
    >
      {{ errorMessage }}
    </b-alert>

    <div class="row">
      <div class="col-12 mb-3">
        <select-paciente
          ref="selectPaciente"
          :value="form.entidade_id"
          :tipo="['paciente']"
          @paciente="handlePacienteSelect"
          label="Paciente"
          showFidelizacoes
          :selecionado="form.entidade_id ? form.entidade_id : ''"
          setNullOnEmpty
        />
      </div>

      <div class="col-md-6">
        <b-form-group label="Valor">
          <b-input-group>
            <b-input
              v-model="form.valor"
              type="text"
              :formatter="currencyFormatter"
              required
              :state="valorState"
              class="text-right"
            />
            <b-input-group-append v-if="form.valor">
              <b-button
                variant="outline-danger"
                @click="form.valor = ''"
                size="sm"
              >
                <i class="fas fa-times"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="valorState">
            O valor deve ser maior que zero
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <template v-if="paymentLink">
      <hr class="my-4" />
      <div class="payment-link-container">
        <h5 class="d-flex align-items-center">
          <i class="fas fa-link mr-2"></i>
          Link de Pagamento Gerado
        </h5>
        <div class="d-flex align-items-center mt-3">
          <b-input-group>
            <b-form-input
              readonly
              :value="paymentLink"
              class="border-right-0"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="copyToClipboard"
                v-b-tooltip.hover
                title="Copiar link"
              >
                <i class="fas fa-copy"></i>
              </b-button>
              <b-button
                variant="success"
                @click="shareOnWhatsApp"
                v-b-tooltip.hover
                title="Compartilhar no WhatsApp"
              >
                <i class="fab fa-whatsapp"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </template>

    <div class="mt-4 d-flex justify-content-end">
      <b-button
        variant="secondary"
        class="mr-2"
        @click="$emit('cancel')"
        :disabled="loading"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="submit"
        :disabled="loading"
      >
        <b-spinner
          small
          v-if="loading"
        ></b-spinner>
        {{ loading ? "Gerando..." : "Gerar Link" }}
      </b-button>
    </div>
  </div>
</template>

<script>
import CaixaRecebimentoAntecipadoLib from "@/libs/CaixaRecebimentoAntecipadoLib";
import SelectPaciente from "@/components/common/SelectPaciente.vue";

export default {
  name: "RecebimentoAntecipado",
  components: {
    SelectPaciente,
  },
  props: {
    caixa: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    error: false,
    errorMessage: "",
    paymentLink: "",
    form: {
      entidade_id: null,
      valor: "",
      paciente: null,
    },
  }),

  computed: {
    valorState() {
      if (!this.form.valor) return null;
      const numericValue = this.getNumericValue(this.form.valor);
      return numericValue > 0;
    },

    isFormValid() {
      return this.form.entidade_id && this.valorState;
    },
  },

  methods: {
    currencyFormatter(value) {
      if (!value) return "";
      value = value.replace(/\D/g, "");
      value = (parseInt(value) / 100).toFixed(2);
      value = value.replace(".", ",");
      value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return `R$ ${value}`;
    },

    getNumericValue(value) {
      if (!value) return 0;
      return parseFloat(value.replace(/\D/g, "")) / 100;
    },

    handlePacienteSelect(paciente) {
      this.form.paciente = paciente;
    },

    resetForm() {
      this.form = {
        entidade_id: null,
        valor: "",
        paciente: null,
      };
      this.error = false;
      this.errorMessage = "";
      this.paymentLink = "";
      this.$emit("hidden");
    },

    async submit() {
      this.loading = true;
      this.error = false;

    //   try {
        console.log(this.caixa, this.form);
        if (!this.caixa || !this.caixa.id) {
          throw new Error("Nenhum caixa aberto encontrado");
        }


        const payload = {
          entidade_id: this.form.paciente?this.form.paciente.id:this.form.entidade_id,
          valor: this.getNumericValue(this.form.valor),
          empresa_id: this.caixa.empresa_id,
          user_id: this.$store.state.auth.user.id,
          caixa_id: this.caixa.id,
        };

        console.log(payload, this.caixa);

        const response = await CaixaRecebimentoAntecipadoLib.createAntecipado(
          payload
        );

        if (response.success) {
          const baseUrl = window.location.origin;
          this.paymentLink = `${baseUrl}/payment/${response.recebimento_id}/${
            this.form.entidade_id
          }/${payload.valor}/${encodeURIComponent(this.form.paciente.nome)}`;
          this.$emit("created");
        } else {
          throw new Error(
            response.message || "Erro ao gerar link de pagamento"
          );
        }
    //   } catch (error) {
    //     this.error = true;
    //     this.errorMessage =
    //       error.response?.data?.message ||
    //       error.message ||
    //       "Erro ao gerar link de pagamento";
    //   } finally {
        this.loading = false;
    //   }
    },

    copyToClipboard() {
      navigator.clipboard
        .writeText(this.paymentLink)
        .then(() => {
          this.$bvToast.toast("Link copiado com sucesso!", {
            title: "Sucesso",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Erro ao copiar link", {
            title: "Erro",
            variant: "danger",
            solid: true,
          });
        });
    },

    shareOnWhatsApp() {
      const message = `Olá ${this.form.paciente.nome}, segue o link para pagamento: ${this.paymentLink}`;
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, "_blank");
    },
  },
};
</script>

<style scoped>
.recebimento-form {
  padding: 1rem;
}

.payment-link-container {
  background-color: #f8f9fa;
  padding: 1.25rem;
  border-radius: 6px;
  border: 1px solid #e9ecef;
}

.payment-link-container h5 {
  color: #2c3e50;
  margin-bottom: 0;
  font-size: 1.1rem;
}

.input-group-text {
  background-color: white;
}

/* Ajuste para o input de valor */
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}

/* Estilo para os botões no input group */
.input-group .btn {
  padding: 0.375rem 0.75rem;
  border-color: #ced4da;
}

.input-group .btn:hover {
  background-color: #e9ecef;
}

/* Ajuste para o ícone de fechar no campo de valor */
.btn-outline-danger {
  border-color: #ced4da;
  color: #6c757d;
}

.btn-outline-danger:hover {
  background-color: #e9ecef;
  color: #dc3545;
  border-color: #ced4da;
}

/* Espaçamento consistente */
.mb-3 {
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

/* Ajustes para responsividade */
@media (max-width: 768px) {
  .recebimento-form {
    padding: 0.5rem;
  }

  .payment-link-container {
    padding: 1rem;
  }
}
</style>
