import { render, staticRenderFns } from "./RecebimentoAntecipado.vue?vue&type=template&id=3b3e5470&scoped=true&"
import script from "./RecebimentoAntecipado.vue?vue&type=script&lang=js&"
export * from "./RecebimentoAntecipado.vue?vue&type=script&lang=js&"
import style0 from "./RecebimentoAntecipado.vue?vue&type=style&index=0&id=3b3e5470&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b3e5470",
  null
  
)

export default component.exports